<template lang="pug">
template(v-if="uxMonitoringReady")
  q-layout.full-height(
    view="lHr LpR lFr",
    style="background: hsla(202, 0%, 0%, 1)"
  )
    Header
    Drawer

    q-page-container.full-height(style="background: #222")
      q-page.full-height.scroll
</template>

<style>
</style>

<script>
import Header from "./2header.vue";
import Drawer from "./2drawer.vue";
export default {
  components: {
    Header,
    Drawer,
  },
  computed: {
    uxMonitoringReady: function () {
      return this.$store.getters.uxMonitoringReady;
    },
  },
  mounted: function () {
    console.debug("✅ 3routes.monitoring.1index.vue loaded");
  },
};
</script>
