<template lang="pug">
q-header.row.no-wrap(height-hint="66", style="background: #222")
  q-toolbar(style="height: 66px")
    c-btn-101(icon="person", label="profile", @click="openProfileDialog")
    q-space
    .q-gutter-xs
      c-btn-101(icon="zoom_out", label="zoom out", @click="zoomOutLayout")
      c-btn-101(icon="zoom_in", label="zoom in", @click="zoomInLayout")
      c-btn-101(
        icon="volume_up",
        label="sound",
        :active="uxMonitoringSoundOn",
        @click="toggleSound"
      )
      c-btn-101(
        icon="notifications",
        label="events",
        :active="uxMonitoringRightDrawerOpen",
        @click="toggleRightDrawer"
      )
</template>

<style>
</style>

<script>
export default {
  computed: {
    uxMonitoringSoundOn: function () {
      return this.$store.getters.uxMonitoringSoundOn;
    },
    uxMonitoringRightDrawerOpen: function () {
      return this.$store.getters.uxMonitoringRightDrawerOpen;
    },
  },
  methods: {
    openProfileDialog: function () {
      this.$c.dialog("profile");
    },
    zoomOutLayout: function () {
      this.$store.commit("ZOOM_OUT_UX_MONITORING_LAYOUT");
    },
    zoomInLayout: function () {
      this.$store.commit("ZOOM_IN_UX_MONITORING_LAYOUT");
    },
    toggleSound: function () {
      this.$store.commit("TOGGLE_UX_MONITORING_SOUND");
    },
    toggleRightDrawer: function () {
      this.$store.commit("TOGGLE_UX_MONITORING_RIGHT_DRAWER");
    },
  },
};
</script>
