<template lang="pug">
q-drawer.bg-grey-1(
  :model-value="uxMonitoringRightDrawerOpen",
  show-if-above,
  side="right",
  behavior="desktop",
  :width=320
)
  q-toolbar.flex.flex-center(style="height: 66px")
    q-tabs.rounded-borders(dense, active-class="c-tab-active")
      c-tab-101(name="alarm", icon="notifications_active", label="ALARM")
      c-tab-101(name="trouble", icon="error", label="TROUBLE")
      c-tab-101(name="arming", icon="verified_user", label="Arming")
      c-tab-101(name="verified", icon="speaker_phone", label="Verified")
</template>

<style>
</style>

<script>
export default {
  computed: {
    uxMonitoringRightDrawerOpen: function () {
      return this.$store.getters.uxMonitoringRightDrawerOpen;
    },
  },
};
</script>
